import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Light installation in a `}<em parentName="h1">{`mate`}</em>{` box`}</h1>
    <p>{`Before the pandemic I was regularly attending the `}<strong parentName="p">{`chaos communication congress (CCC)`}</strong>{` and was very
inspired by all the nice `}<strong parentName="p">{`art installations`}</strong>{` that are presented there every year.
One of them I liked particularly well.
It was a light installation made of `}<strong parentName="p">{`mate box`}</strong>{` and `}<strong parentName="p">{`bottles`}</strong>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      